@import './../_variables'
@import './../_mixins'

.auth-page-main
    padding: $medium-space 0
    +flexbox(center, center, column, nowrap)
    min-height: 100vh

    &__inner
        +flexbox(center, center, column, nowrap)
        width: 90%
        max-width: 300px
        gap: $medium-space

        &__header
            +flexbox(center, flex-start, column, wrap)
            position: relative
            width: 100%

    .main-logo-container
        height: $medium-space
        width: $medium-space*.84
        +flexbox(center, center, column, wrap)
        margin-bottom: $small-space

        > img
            position: relative
            max-height: auto
            width: 100%

    .auth-input-container
        +flexbox(flex-start, center, column, nowrap)
        gap: $small-space
        position: relative
        width: 100%

.auth-internal-link-container
    +flexbox(flex-start, flex-start, column, nowrap)
    gap: $small-space*.5
    width: 100%

.auth-internal-link
    color: $main-text-color

.password-suggestion-message
    margin: 0
    font-size: .9em