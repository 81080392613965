@import '../../styles/_variables'
@import '../../styles/_mixins'
    
.modal
    +flexbox(center, center, column, nowrap)
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 8

    .dark-cover
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background-color: rgba($black, .5)

    .modal-panel
        position: relative
        max-height: 90%
        width: $large-space*5
        background-color: $main-background-color
        border-radius: $main-border-radius
        padding: $small-space
        +flexbox(center, center, column, nowrap)

        .alert-message
            padding-top: $small-space*.6
            width: 100%
            font-size: .94em
            letter-spacing: .4px
            text-align: center

        .alert-modal-footer
            position: relative
            width: 100%
            +flexbox(center, center, row, nowrap)
            gap: $small-space
            padding-top: $small-space

            .gradient-button
                width: auto
                flex: 1

        .available-plans-subtitle
            position: relative
            width: 100%
            font-size: .9em
            color: $silver
            letter-spacing: .3px
            text-align: left

        .available-plans-list
            position: relative
            width: 100%
            +flexbox(center, center, column, nowrap)
            margin: $small-space 0
            gap: $small-space

            .selected-plan-card
                border-color: $silver !important
                border-width: 2px !important

            .plan-card
                cursor: pointer
                position: relative
                width: 100%
                padding: $small-space
                border: 1px solid darken($silver, 40%)
                border-radius: $main-border-radius
                background-color: darken($main-background-color, 5%)
                +flexbox(space-between, center, row, nowrap)

                &__left-section
                    +flexbox(flex-start, flex-start, column, nowrap)

                .plan-title
                    font-size: .9em
                    opacity: .9

                .plan-token-balance,
                .plan-price
                    font-weight: 600
