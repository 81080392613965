@import './_variables'
@import './_mixins'

:root
  --font-size: 14px

*
  box-sizing: border-box
  outline: none

::-webkit-input-placeholder
  color: $silver

::-moz-placeholder
  color: $silver

:-ms-input-placeholder
  color: $silver

:-moz-placeholder
  color: $silver

html,
body
  height: 100%
  overflow-x: hidden

body 
  position: relative
  margin: 0
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $main-text-color
  background-color: $main-background-color
  font-size: var(--font-size)

body,
input,
textarea
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif

code 
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

.success-container,
.error-container
  +flexbox(flex-start, center, row, nowrap)
  font-size: .9em
  font-weight: 500
  letter-spacing: .4px
  line-height: 130%
  border-radius: $main-border-radius
  padding: $small-space*0.5 $small-space

.error-container
  border: 1px solid $red
  color: $red
  background-color: rgba(darken($red, 6%), .14)

.success-container
  border: 1px solid $green
  color: $green
  background-color: rgba(darken($green, 20%), .14)

.Toastify
  position: absolute

.Toastify__toast
  border-radius: $main-border-radius + 2 !important
  background-color: $main-background-color

.Toastify__close-button
  align-self: auto !important