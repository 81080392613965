@import '../../styles/_variables'
@import '../../styles/_mixins'

.scroller-container
    max-height: calc(100vh - 60px)
    padding: 0 32px 107.52px
    overflow-y: auto

.circular-loader-wrapper
    +flexbox(center, center, column, nowrap)
    width: 100%

    .circular-loader
        background-color: $main-text-color
        border-radius: 50%
        width: $medium-space*1.2
        height: $medium-space*1.2
        border: 1px solid $silver