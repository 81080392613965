@import '../../styles/_variables'
@import '../../styles/_mixins'

.chat-panel-wrapper
    position: relative
    height: calc(100vh - 60px)
    overflow: hidden

    .chat-panel-background
        position: fixed
        background-color: $chat-background-color
        top: 0
        bottom: 0
        left: 0
        right: 0

    .chat-input-section
        position: fixed
        bottom: $small-space*3
        left: 250px
        right: 0
        // height: 60px
        max-height: 100px
        // background-color: $main-background-color
        +flexbox(center, center, row, nowrap)
        visibility: hidden

        &__inner
            visibility: visible
            +flexbox(center, center, row, nowrap)
            gap: $small-space
            flex: 1
            width: 100%
            max-width: 1500px
            margin: 0 $medium-space

            &__button--primary
                transition: background-color 120ms linear
                &:hover
                    background-color: $blue

            &__button
                cursor: pointer
                position: relative
                +flexbox(center, center, row, nowrap)
                background-color: transparentize($black, .3)
                +square($small-space)
                padding: $small-space
                border-radius: 50%
                border: 1px solid $chat-background-color
                transition: border-color 120ms linear

                &:hover
                    border-color: $divider-color

.active-context-feature
    background-color: $black !important
    color: $fuchsia

.disabled-chat-input-button
    cursor: not-allowed
    opacity: .8
    pointer-events: none
