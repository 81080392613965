@import '../../styles/_variables'
@import '../../styles/_mixins'

.main-sidebar
    z-index: 2
    background-color: $main-background-color
    position: fixed
    width: 250px
    height: 100vh
    top: 0
    +flexbox(flex-start, flex-start, column, nowrap)
    padding: $medium-space $medium-space*.8
    border-right: 1px solid $divider-color

    &__logo
        height: $medium-space
        width: $medium-space*.84
        +flexbox(center, center, column, wrap)
        margin-bottom: $medium-space

        > img
            position: relative
            max-height: auto
            width: 100%

    &__topics-container
        +flexbox(flex-start, flex-start, column, nowrap)
        gap: $small-space
        width: 100%
        flex: 1
        overflow-y: auto
        margin-top: $medium-space
        padding-right: $small-space

    &__bottom-section
        +flexbox(flex-start, center, column, nowrap)
        width: 100%
        gap: $small-space*.5
        margin-top: $medium-space

        &__bottom-credits
            color: opacify($main-text-color, .94)
            font-size: .7em
            letter-spacing: .6px
            font-weight: 600
            line-height: 140%
            margin: 0

.sidebar-external-link
    color: $main-text-color