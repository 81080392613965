@import '../../styles/_variables'
@import '../../styles/_mixins'

.message-box
    position: relative
    width: 100%
    +flexbox(flex-start, flex-start, column, wrap)

    .message-bubble
        position: relative
        max-width: 94%
        padding: $small-space*.4 $small-space*.6
        background: $main-text-color
        border-radius: $main-border-radius*2
        margin-bottom: $small-space*.6

        .text-wrapper
            font-size: .95em
            font-weight: 400
            letter-spacing: .3px
            line-height: 140%
            word-break: break-word
            white-space: pre-line

        .message-footer
            -webkit-user-select: none
            -ms-user-select: none
            user-select: none
            pointer-events: none
            position: relative
            width: 100%
            font-size: .84em
            padding-top: $small-space*.2
            +flexbox(space-between, center, row, nowrap)

            .time-indicator
                +flexbox(flex-end, flex-end, row, nowrap)
                flex: 1
                opacity: .8

                &::before
                    content: attr(data-unselectable)

            .token-spent-wrapper
                +flexbox(center, center, row, nowrap)
                gap: $small-space*.4
                opacity: .8

                &__count
                    line-height: 100%
                    position: relative
                    top: 1px

                    &::before
                        content: attr(data-unselectable)

                &__image-container
                    +flexbox(center, center, row, nowrap)
                    +square($small-space*.8)

                    > img
                        position: relative
                        width: 100%
                        height: auto

.message-invalid-image
    overflow: hidden

    > img
        filter: blur(25px)

.message-image-container
    +flexbox(center, center, column, nowrap)
    +square($large-space*4)
    border-radius: $main-border-radius
    background: $black
    overflow: hidden
    margin: 0 $small-space*(-0.2)

    .invalid-image-message
        z-index: 1
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        +flexbox(center, center, column, nowrap)
        text-align: center
        font-weight: 600
        font-size: .9em
        padding: 0 $small-space

    > img
        z-index: 0
        position: relative
        height: 90%

.request-message
    .message-bubble
        margin-left: auto
        background-color: $blue

.response-message
    .message-bubble
        margin-right: auto

.response-message
    .message-bubble
        background-color: $darkGray

.loading-message
    height: $large-space*.6
    width: $large-space
    margin-right: auto

.loading-message,
.error-message
    +flexbox(center, center, row, nowrap)
    background-color: $main-text-color
    border-radius: $main-border-radius*2

.error-message
    cursor: pointer
    margin-left: auto
    height: $large-space*.8
    width: $large-space*4.5
    color: $black
    padding-right: $small-space
    overflow: hidden
    margin-bottom: $small-space

    .error-message-text
        font-weight: 600
        font-size: .9em
        letter-spacing: .3px
        line-height: 120%

.loading-message
    .lottie
        position: relative
        width: 100%
        height: auto
        top: 2px
        transform: scale(1.5)

.error-message
    &__lottie-container
        height: $large-space
        width: $large-space

        .lottie
            position: relative
            width: 100%
            height: 100%
            transform: scale(.8)
