@import '../../styles/_variables'
@import '../../styles/_mixins'

.topic-card
    cursor: pointer
    +flexbox(center, center, column, wrap)
    position: relative
    border-radius: $main-border-radius
    overflow: hidden
    width: 100%
    flex-shrink: 0
    transition: opacity 120ms linear

    &__text-container
        height: 100%
        width: 100%
        padding: $small-space * .6 $small-space
        z-index: 2
        border: 1px solid $divider-color
        +flexbox(flex-start, flex-start, column, wrap)

        &__title,
        &__description
            word-break: break-word

        &__title
            font-size: 1.2em
            font-weight: 600
            line-height: 110%

        &__description
            margin-top: $small-space*0.4
            font-size: 0.74em
            letter-spacing: .3px

    &:hover
        opacity: .7