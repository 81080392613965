@import '../../styles/_variables'
@import '../../styles/_mixins'

.gradient-button
    appearance: none
    border: 1px solid rgba($main-text-color, .8)
    background: $blue
    background: linear-gradient(45deg, rgba($blue, 1) 35%, rgba($navy, 1) 100%)
    color: $main-text-color
    padding: $small-space*.5 $small-space*.8
    cursor: pointer
    font-weight: 600
    border-radius: $main-border-radius
    +flexbox(center, center, row, nowrap)
    flex-shrink: 0
    overflow: hidden
    width: 100%

    &:disabled
        opacity: .6
        cursor: not-allowed

.grayscale-button
    background: rgba($black, .6)