// Cold colors
$aqua: #7fdbff
$blue: #0074d9
$navy: #001f3f
$teal: #39cccc
$green: #2ecc40
$olive: #3d9970
$lime: #01ff70
$petrolium: #1e2a3b

// Warm colors
$yellow: #ffdc00
$orange: #ff851b
$red: #ff4136
$fuchsia: #f012be
$pink: #eae7fe
$purple: #b10dc9
$maroon: #85144b

// Grayscale
$ffffff: #ffffff
$silver: #dddddd
$gray: #aaaaaa
$darkGray: #5C6E7E
$black: #111111

$main-background-color: $petrolium
$chat-background-color: $pink
$main-text-color: $ffffff
$divider-color: rgba($ffffff, .07)

$main-text-size: 14px
$main-border-radius: 6px

$small-space: 16px
$medium-space: 32px
$large-space: 64px

$phone-size: 600px
$tablet-size: 992px
$desktop-size: 1200px