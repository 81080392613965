@import '../../styles/_variables'
@import '../../styles/_mixins'

.text-input-wrapper
    border: 1px solid $main-text-color
    background-color: $darkGray
    border-radius: $main-border-radius
    +flexbox(flex-start, center, row, nowrap)
    overflow: hidden
    width: 100%

    .text-input
        appearance: none
        border: none
        background: none
        padding: $small-space*.4 $small-space*.5
        width: 100%
        color: $main-text-color
