@import '../../styles/_variables'
@import '../../styles/_mixins'
    
.tooltip-wrapper
    z-index: 9
    +flexbox(center, center, column, nowrap)
    position: relative

    &:hover
        .tooltip
            opacity: 1

    .tooltip
        position: absolute
        font-size: .85em
        padding: $small-space*.5 $small-space*.6
        color: $main-text-color
        background-color: rgba($black, .8)
        border-radius: $main-border-radius
        letter-spacing: .34px
        line-height: 120%
        opacity: 0
        border: 1px solid $pink
        pointer-events: none
        transition: opacity 150ms linear

    .tooltip-top
        top: $medium-space*(-1.2)
    
    .tooltip-bottom
        bottom: $medium-space*(-1.2)
