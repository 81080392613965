@import '../../styles/_variables'
@import '../../styles/_mixins'

.coins-balance
    +flexbox(flex-start, center, row, nowrap)
    gap: $small-space*.5
    cursor: pointer

.coins-image-container
    +square($small-space + 4)
    +flexbox(center, center, column, nowrap)

    > img
        position: relative
        height: 100%

.coins-balance-value
    font-size: .9em
    font-weight: 600
    letter-spacing: .3px
    margin-top: $small-space*.2
    cursor: inherit
