@import '../../styles/_variables'
@import '../../styles/_mixins'
    
.main-header
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 1
    +flexbox(space-between, center, row, nowrap)
    height: 60px
    padding-left: 250px + $medium-space
    padding-right: $medium-space
    border-bottom: 1px solid $divider-color
    background-color: $main-background-color

    &__left-section,
    &__right-section
        gap: $small-space

    &__left-section
        +flexbox(flex-start, center, row, nowrap)

        &__title
            font-size: 1.5em
            font-weight: 600

        &__font-size
            +flexbox(flex-start, center, row, nowrap)
            gap: $small-space*.5
            margin-left: $small-space

            .disabled-font-size-button
                pointer-events: none
                opacity: .5

            .font-change-button
                +flexbox(center, center, column, nowrap)
                +square($small-space*1.4)
                background-color: lighten($main-background-color, 20%)
                line-height: 100%
                text-align: center
                border-radius: $main-border-radius*.4
                padding: $small-space*.4
                cursor: pointer

                &:hover
                    background-color: darken($main-background-color, 10%)

                .increase
                    font-size: 19px
                    font-weight: 700

                .decrease
                    font-size: 12px
                    font-weight: 600

    &__right-section
        +flexbox(center, center, row, nowrap)

        .user-avatar-container
            cursor: pointer
            position: relative
            +flexbox(center, center, row, nowrap)
            background-color: transparentize($black, .5)
            +square($small-space)
            padding: $small-space
            border-radius: 50%
            border: 1px solid transparent
            transition: border-color 120ms linear

            .user-avatar-icon
                position: absolute
                color: $main-text-color

            &:hover
                border-color: $divider-color
