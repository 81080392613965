@import './../_variables'
@import './../_mixins'

.profile-page-main
    padding: $medium-space 0
    +flexbox(center, center, column, nowrap)
    min-height: 100vh

    &__inner
        +flexbox(center, center, column, nowrap)
        width: 90%
        max-width: 300px
        gap: $small-space

        &__header
            +flexbox(center, flex-start, column, wrap)
            position: relative
            width: 100%

    .main-logo-container
        height: $medium-space
        width: $medium-space*.84
        +flexbox(center, center, column, wrap)
        margin-bottom: $small-space

        > img
            position: relative
            max-height: auto
            width: 100%

    .profile-input-container
        +flexbox(flex-start, flex-start, column, nowrap)
        gap: $small-space*.3
        position: relative
        width: 100%
        margin-bottom: $small-space

.profile-input-section
    width: 100%
    padding-bottom: $small-space
    border-bottom: 2px solid rgba($silver, .3)

.profile-internal-link-container
    text-decoration: none

    &__link
        +flexbox(flex-start, center, row, nowrap)
        gap: 1ch
        color: $main-text-color
        font-weight: 600

.profile-internal-link
    color: $main-text-color

.password-suggestion-message
    margin: $small-space 0
    font-size: .9em
    letter-spacing: .4px
    opacity: .86
    line-height: 140%

.dangerous-links-section
    +flexbox(flex-start, flex-start, column, nowrap)
    gap: $small-space

.dangerous-action-link
    color: $red
    letter-spacing: .3px
    cursor: pointer
    font-weight: 600
    line-height: 100%

.elements-row
    width: 100%
    +flexbox(space-between, center, row, nowrap)

.basic-text-paragraph
    margin: 0
